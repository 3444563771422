import { Ask } from "./Ask";

export const AskMe = () => {
  return (
    <Ask
      id="ask-me"
      placeholders={[
        "How did you learn to code?",
        "What are some great developer tools today?",
        "What stack do you prefer to work with?",
        "What are you building?",
        "Beaches or Mountains?",
      ]}
      apiRoute="askTejas"
      stream
    />
  );
};
